import { Api } from "@/app/api";
import {
	BlogArticle,
	BlogArticleStatus,
	BlogCommentCollectionResponse,
	BlogLikesCollectionResponse,
	CreateBlogArticle,
} from "@/types";

export const blogInjection = Api.injectEndpoints({
	endpoints: (builder) => ({
		getBlogs: builder.query<BlogArticle, void>({
			query: () => ({
				url: "/v1/blogs",
			}),
			providesTags: ["blog"],
		}),

		getBlog: builder.query<BlogArticle, string>({
			query: (id) => `/v1/blogs/${id}`,
		}),

		createBlog: builder.mutation<BlogArticle, CreateBlogArticle>({
			query: (body) => ({
				url: "/v1/blogs",
				method: "POST",
				body: JSON.stringify(body),
				headers: {
					"Content-Type": "application/json",
				},
			}),
			invalidatesTags: ["category", "blog"],
		}),
		updateBlog: builder.mutation<void, { blogId: string; body: Partial<CreateBlogArticle> }>({
			query: ({ blogId, body }) => ({
				url: `/v1/blogs/${blogId}`,
				method: "PATCH",
				body: JSON.stringify(body),
				headers: {
					"Content-Type": "application/json",
				},
			}),
			invalidatesTags: ["blog"],
		}),
		searchBlogs: builder.query<
			{ docs: BlogArticle[] },
			{
				searchQuery: string;
				sortBy?: string;
				status?: BlogArticleStatus;
				page: number;
				limit: number;
				categoryID?: string;
			}
		>({
			query: ({ searchQuery, sortBy, page, limit, categoryID, status }) => ({
				url: `/v1/blogs`,
				params: {
					category: categoryID,
					page: page,
					limit: limit,
					search: searchQuery,
					status,
					sortBy: sortBy,
				},
				headers: {
					"Content-Type": "application/json",
				},
			}),
			providesTags: ["blog"],
		}),
		getBlogLikes: builder.query<
			BlogLikesCollectionResponse,
			{ id: string; limit?: number; userId?: string }
		>({
			query: ({ id, limit, userId }) => ({
				url: `/v1/blogs/${id}/likes`,
				params: { limit, userId },
			}),
			providesTags: ["blog-likes"],
		}),
		getBlogComments: builder.query<
			BlogCommentCollectionResponse,
			{ id: string; page?: number; limit?: number }
		>({
			query: ({ id, page, limit }) => ({
				url: `/v1/blogs/${id}/comments`,
				params: { page, limit },
			}),
			providesTags: ["blog-comments"],
		}),
		getAllComments: builder.query<
			BlogCommentCollectionResponse,
			{ searchQuery?: string; sortBy?: string; page: number; limit: number }
		>({
			query: ({ searchQuery, sortBy, page, limit }) => ({
				url: `/v1/blogs/comments`,
				params: {
					page: page,
					limit: limit,
					search: searchQuery,
					sortBy: sortBy,
				},
				headers: {
					"Content-Type": "application/json",
				},
			}),
			providesTags: ["blog-comments"],
		}),
		createComment: builder.mutation<
			BlogArticle,
			{ id: string; body: { userId: string; text: string } }
		>({
			query: ({ id, body }) => ({
				url: `/v1/blogs/${id}/comments`,
				method: "POST",
				body: JSON.stringify(body),
				headers: {
					"Content-Type": "application/json",
				},
			}),
			invalidatesTags: ["blog-comments"],
		}),
		updateComment: builder.mutation<
			void,
			{ blogId: string; commentId: string; content: string }
		>({
			query: ({ blogId, commentId, content }) => ({
				url: `/v1/blogs/${blogId}/comments/${commentId}`,
				method: "PATCH",
				body: { text: content },
			}),
			invalidatesTags: ["blog-comments"],
		}),
		deleteComment: builder.mutation<void, { blogId: string; commentId: string }>({
			query: ({ blogId, commentId }) => ({
				url: `/v1/blogs/${blogId}/comments/${commentId}`,
				method: "DELETE",
			}),
			invalidatesTags: ["blog-comments"],
		}),
		likeBlog: builder.mutation<BlogArticle, { id: string; body: { userId: string } }>({
			query: ({ id, body }) => ({
				url: `/v1/blogs/${id}/likes`,
				method: "POST",
				body: JSON.stringify(body),
				headers: {
					"Content-Type": "application/json",
				},
			}),
			invalidatesTags: ["blog-likes"],
		}),
		unlikeBlog: builder.mutation<unknown, { id: string; likeId: string }>({
			query: ({ id, likeId }) => ({
				url: `/v1/blogs/${id}/likes/${likeId}`,
				method: "DELETE",
			}),
			invalidatesTags: ["blog-likes"],
		}),
	}),
});

export const {
	useGetBlogsQuery,
	useGetBlogQuery,
	useSearchBlogsQuery,
	useCreateBlogMutation,
	useUpdateBlogMutation,
	useGetBlogLikesQuery,
	useGetBlogCommentsQuery,
	useCreateCommentMutation,
	useUpdateCommentMutation,
	useDeleteCommentMutation,
	useGetAllCommentsQuery,
	useLikeBlogMutation,
	useUnlikeBlogMutation,
} = blogInjection;
