import Link from "next/link";
import Image from "next/image";

import type { Service } from "@/types";
import { getPreviewMedia } from "@/lib/utils";

import { Card, CardHeader, CardTitle } from "@/components/ui/card";

export default function ServiceCard({ service }: { service: Service }) {
	const preview = getPreviewMedia(service.media, service.thumbnailImage);
	if (preview === null) return null;

	return (
		<Link href={`/services/${service.id}`}>
			<Card className="custom-box-shadow flex max-h-80 flex-col rounded-lg sm:w-80">
				<CardHeader aria-hidden className="h-52 w-full rounded-t-lg p-0">
					<Image
						src={preview.url}
						alt=""
						width={512}
						height={512}
						quality={80}
						className="size-full rounded-t-lg object-cover"
					/>
				</CardHeader>
				<CardTitle className="inline-flex h-24 items-center px-4 text-sm font-medium">
					{service.title}
				</CardTitle>
			</Card>
		</Link>
	);
}
