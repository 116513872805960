import { ChevronLeft, ChevronRight } from "lucide-react";
import { type MouseEventHandler, useCallback, useMemo } from "react";
import {
	type ArrowProps,
	type DotProps as LibDotProps,
	type ButtonGroupProps,
} from "react-multi-carousel";

import { clamp, cn } from "@/lib/utils";

type DotProps = Omit<LibDotProps, "onClick"> & { onClick: MouseEventHandler<HTMLButtonElement> };

export function ArrowButtonLeft({
	onClick,
	disabled,
	className,
}: Pick<ArrowProps, "onClick"> & { disabled: boolean; className?: string }) {
	return (
		<button
			disabled={disabled}
			onClick={(e) => {
				e.stopPropagation();
				onClick?.call(undefined);
			}}
			className={cn("size-8 text-secondary disabled:opacity-50", className)}
		>
			<ChevronLeft className="size-8" />
		</button>
	);
}

export function ArrowButtonRight({
	onClick,
	disabled,
	className,
}: Pick<ArrowProps, "onClick"> & { disabled: boolean; className?: string }) {
	return (
		<button
			disabled={disabled}
			onClick={(e) => {
				e.stopPropagation();
				onClick?.call(undefined);
			}}
			className={cn("size-8 text-secondary disabled:opacity-50", className)}
		>
			<ChevronRight className="size-8" />
		</button>
	);
}

export function CustomDotWeak(props: DotProps) {
	return (
		<button
			aria-label={`Go to ${props.index} slide`}
			onClick={props.onClick}
			className={cn("size-2 rounded-full bg-grey/30 disabled:opacity-50", {
				"bg-secondary": props.active,
			})}
		/>
	);
}

export function CustomDot({
	index,
	active,
	disabled,
	onClick,
}: Required<Omit<DotProps, "carouselState">> & { disabled: boolean }) {
	return (
		<button
			disabled={disabled}
			aria-label={`Go to ${index} slide`}
			onClick={onClick}
			className={cn("size-2 rounded-full bg-muted-foreground disabled:opacity-50", {
				"bg-secondary": active,
			})}
		/>
	);
}

type ArbitraryButtonGroupProps = { itemLength: number; infinite?: boolean } & ButtonGroupProps;

function DotList({
	itemLength,
	disabled,
	carouselState,
	goToSlide,
	infinite = false,
}: ArbitraryButtonGroupProps & { disabled: boolean }) {
	const dotSize = useMemo(() => clamp(1, 6, itemLength), [itemLength]);

	/**
	 * Note(Curstantine):
	 * If the standard index (so the actual item count) is bigger than the dotSize limit, mark the dot before the last one as active.
	 * If it _is_ above dotSize, but the last item in the actual item list, mark last dot as active.
	 * Everything else could represent their actual index.
	 */
	const getActiveIndex = useCallback(
		(i: number, standardIndex: number) => {
			if (standardIndex === itemLength - 1) return i === dotSize - 1;
			if (standardIndex > dotSize - 2) return i === dotSize - 2;

			return i === standardIndex;
		},
		[dotSize, itemLength],
	);

	if (carouselState === undefined) return null;

	/**
	 * Note(Curstantine):
	 * When the carousel is infinitely moving, the list is padded by 2.
	 * So the currentSlide state is always + 2
	 */
	const { currentSlide: cx } = carouselState;
	const currentSlide = infinite ? cx - 2 : cx;

	/**
	 * Note(Curstantine):
	 * The carousel implementation "clones" the items by a certain multiplier to increase the perception of the list.
	 *
	 * To pinpoint the clone's parent (in a scenario with totalItems=12, itemLength=4, current=6)
	 * ```
	 * const sequenceIndex = current / itemLength; // 1.5 floored to 1
	 * const index = (current - (sequenceIndex * itemLength)) // (6 - (1 * 4)) = 2
	 * ```
	 */
	const cloneSequenceIndex = Math.floor(currentSlide / itemLength);
	const standardIndex = currentSlide - cloneSequenceIndex * itemLength;

	const onClick = (i: number) => {
		let nearest = 0;
		const indexLastItem = itemLength - 1;

		if (standardIndex < indexLastItem) {
			nearest = currentSlide + (i - standardIndex);
		} else {
			// TODO(Curstantine):
			// I'm sorry but I can't be bothered to think this through
			nearest = i;
		}

		goToSlide?.call(undefined, nearest);
	};

	return (
		<ul className="inline-flex gap-2 px-2">
			{new Array(dotSize).fill(null).map((_, i) => (
				<li key={i}>
					<CustomDot
						index={i}
						active={getActiveIndex(i, standardIndex)}
						disabled={disabled}
						onClick={(e) => {
							e.stopPropagation();
							onClick(i);
						}}
					/>
				</li>
			))}
		</ul>
	);
}

export function CustomButtonGroup({
	next,
	previous,
	carouselState,
	goToSlide,
	itemLength,
	infinite,
	showArrows = true,
}: ArbitraryButtonGroupProps & { showArrows?: boolean }) {
	const allShown = carouselState?.totalItems === itemLength;
	const localShowArrows = showArrows && itemLength > 1;

	return (
		<div className="absolute inset-x-0 bottom-0 flex items-center justify-center gap-4">
			{localShowArrows && <ArrowButtonLeft disabled={allShown} onClick={previous} />}
			{itemLength > 1 && (
				<DotList
					disabled={allShown}
					itemLength={itemLength}
					carouselState={carouselState}
					goToSlide={goToSlide}
					infinite={infinite}
				/>
			)}
			{localShowArrows && <ArrowButtonRight disabled={allShown} onClick={next} />}
		</div>
	);
}
