import { Api } from "@/app/api";
import { SavedBlogs, SavedServices } from "@/types";

export const savedInjection = Api.injectEndpoints({
	endpoints: (builder) => ({
		getSavedServices: builder.query<SavedServices, string>({
			query: (id) => ({
				url: `/v1/saved-services/${id}`,
			}),
			providesTags: ["services-saved"],
		}),

		setSavedServices: builder.mutation<
			SavedServices,
			{
				userId: string;
				serviceIds: string[];
			}
		>({
			query: (body) => ({
				url: `/v1/saved-services`,
				method: "POST",
				body: body,
			}),
			invalidatesTags: ["services-saved"],
		}),

		getSavedBlogs: builder.query<SavedBlogs, string>({
			query: (id) => ({
				url: `/v1/saved-blogs/${id}`,
			}),
			providesTags: ["blog-saved"],
		}),
		setSavedBlogs: builder.mutation<
			SavedBlogs,
			{
				userId: string;
				blogIds: string[];
			}
		>({
			query: (body) => ({
				url: `/v1/saved-blogs`,
				method: "POST",
				body: body,
			}),
			invalidatesTags: ["blog-saved"],
		}),
	}),
});

export const {
	useGetSavedServicesQuery,
	useSetSavedBlogsMutation,
	useGetSavedBlogsQuery,
	useSetSavedServicesMutation,
} = savedInjection;
