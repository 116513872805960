import type { PropsWithoutRef, SVGProps } from "react";

export default function LikeCircleIcon({ ...props }: PropsWithoutRef<SVGProps<SVGElement>>) {
	return (
		<svg
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM5.71783 8.97791H7.53769C7.83921 8.97791 8.08364 9.22234 8.08364 9.52387V14.9834C8.08364 15.285 7.83921 15.5294 7.53769 15.5294H5.71783C5.4163 15.5294 5.17188 15.285 5.17188 14.9834V9.52387C5.17188 9.22234 5.4163 8.97791 5.71783 8.97791ZM6.0818 14.0735C6.0818 14.375 6.32623 14.6195 6.62776 14.6195C6.92929 14.6195 7.17371 14.375 7.17371 14.0735C7.17371 13.772 6.92929 13.5275 6.62776 13.5275C6.32623 13.5275 6.0818 13.772 6.0818 14.0735ZM13.4577 7.18611C13.6727 6.78275 13.9072 6.34305 13.9072 5.73521C13.9072 4.06431 12.8153 3.88232 12.2693 3.88232C11.8134 3.88232 11.6015 4.76385 11.4995 5.18823L11.4974 5.19703C11.379 5.68989 11.2565 6.19954 10.9198 6.53694C10.5715 6.88579 10.3013 7.27975 10.0185 7.69207C9.70155 8.15421 9.36877 8.63941 8.89252 9.11001C8.84075 9.16119 8.81158 9.23105 8.81158 9.30385V14.1667C8.81158 14.3155 8.93085 14.4369 9.07963 14.4397C9.43973 14.4463 9.91435 14.6462 10.2773 14.8077C10.9997 15.129 11.8987 15.529 12.9971 15.5294L13.0618 15.5294C14.0349 15.5294 15.1857 15.52 15.65 14.8529C15.8409 14.5786 15.8876 14.2379 15.7898 13.8376C16.1609 13.4497 16.36 12.7261 16.1625 12.137C16.5616 11.604 16.598 10.8601 16.3742 10.3293L16.3767 10.3268C16.6473 10.055 16.8208 9.61568 16.8189 9.20762C16.8154 8.51717 16.2239 7.886 15.4642 7.886H13.1502C13.2117 7.64744 13.3313 7.42305 13.4577 7.18611Z"
				fill="#07A8BC"
			/>
		</svg>
	);
}
